import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { uploadImage, createNewTransportUnit, deleteTransportUnit, getAllTransportUnits, updateTransportUnit } from '../../controllers/transport_controller';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Transports = () => {
    const [transportUnitsData, setTransportUnitsData] = useState([]);
    const [refreshController, setRefreshController] = useState(false);
    const [loaderController, setLoaderController] = useState(false);
    const [modalController, setModalController] = useState(false);
    const [editController, setEditController] = useState(false);
    const [formData, setFormData] = useState({
        name: '', label: '', capacity: '', amenities: '', details: '', image: '', id_unit: '',
    });

    useEffect(() => {
        getAllTransportUnits().then((response) => {
            setTransportUnitsData(response.data);
        }).catch((e) => {
            console.log(e);
        });
        setRefreshController(false);
    }, [refreshController]);

    const openModal = () => {
        setEditController(false);
        setModalController(true);
    }

    const toggleModal = () => {
        setModalController(!modalController);
        setFormData({ name: '', label: '', capacity: '', amenities: '', details: '', image: 'URL-img', id_unit: '' });
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleFormSubmitCreate = (e) => {
        e.preventDefault();
        setLoaderController(true);

        let dataToSend = {
            name: formData.name,
            label: formData.name,
            capacity: parseInt(formData.capacity),
            amenities: formData.amenities,
            details: formData.details,
            image: 'No image'
        }

        if(formData.image !== ""){
                
            let _formData = new FormData();
            _formData.append('image', formData.image);

            uploadImage(_formData).then( response => {

                dataToSend.image = response.data.image_url;
                _createNewTransport(dataToSend);

            });

        }else{
            _createNewTransport(dataToSend);
        }
    }

    const _createNewTransport = (data) => {

        createNewTransportUnit(data).then((res) => {
            setFormData({ name: '', label: '', capacity: '', amenities: '', details: '', image: '', id_unit: '' });
            setLoaderController(false)
            Swal.fire('Success!', 'The transport unit has been created successfully.', 'success').then(() => {
                toggleModal();
                setRefreshController(true);
            });
        }).catch((e) => {
            console.log(e);
            Swal.fire('Oops!', 'Something went wrong when creating new unit', 'error');
            setLoaderController(false);
        });

    }

    const handleDeleteUnit = (unitData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "The transport unit information will be deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTransportUnit(unitData.id_unit).then(() => {
                    Swal.fire('Success!', 'The transport unit has been deleted successfully.', 'success').then(() => {
                        setRefreshController(true);
                    });
                }).catch((e) => {
                    Swal.fire('Oops!', 'Something went wrong when deleting the selected unit', 'error');
                });
            }
        })
    }

    const handleEditUnit = (data) => {
        setEditController(true);
        setFormData({
            name: data.name, 
            label: data.label, 
            capacity: data.capacity, 
            amenities: data.amenities, 
            details: data.details, 
            image: "",
            id_unit: data.id_unit
        })
        setModalController(true);
    }

    const handleFormSubmitEdit = (e) => {
        e.preventDefault();
        setLoaderController(true);
        
        let dataToSend = {
            name: formData.name,
            label: formData.name,
            capacity: parseInt(formData.capacity),
            amenities: formData.amenities,
            details: formData.details,
            image: 'No image',
        }

        if(formData.image !== ""){
                
            let _formData = new FormData();
            _formData.append('image', formData.image);

            uploadImage(_formData).then( response => {

                dataToSend.image = response.data.image_url;
                _updateTransport(formData.id_unit, dataToSend);

            });

        }else{
            _updateTransport(formData.id_unit, dataToSend);
        }
        
    }

    const _updateTransport = (id, data) => {

        updateTransportUnit(id, data).then((res) => {
            setFormData({ name: '', label: '', capacity: '', amenities: '', details: '', image: 'URL-img', id_unit: '' });
            setLoaderController(false)
            Swal.fire('Success!', 'The transport unit has been updated successfully.', 'success').then(() => {
                toggleModal();
                setRefreshController(true);
            });
        }).catch((e) => {
            console.log(e);
            Swal.fire('Oops!', 'Something went wrong when updating unit', 'error');
            setLoaderController(false);
        });

    }

    const onFileChange = event => {      
        setFormData({
            ...formData,
            image: event.target.files[0]
        });
    }; 

    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                        <h1>VEHICLES TRANSPORTS</h1>
                        <Button color="primary" onClick={openModal}>
                            <FontAwesomeIcon className="mr-2" icon="plus-circle" />Add unit
                        </Button>
                    </Col>
                </Row>
                <Row className="my-3">
                    {
                        transportUnitsData.map((unit, index) => {
                            return <Col xs={3} className="my-4" key={index}>
                                <Card>
                                    <CardImg top width="100%" src={unit.image} />
                                    <CardBody>
                                        <CardTitle>{unit.name}</CardTitle>
                                        <CardSubtitle>Pax: <span>{unit.capacity}</span></CardSubtitle>
                                        <CardText>{unit.details}</CardText>
                                        <div className="button-actions d-flex justify-content-between">
                                            <Button color="danger" onClick={() => handleDeleteUnit(unit)} block>Delete</Button>
                                            <Button color="primary" onClick={() => handleEditUnit(unit)} className="ml-2" block>Edit</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        })
                    }
                </Row>
            </Container>
            <Modal isOpen={modalController}  size='xl' scrollable={true} >
                <LoadingOverlay active={loaderController} spinner text='Loading'>
                    <ModalHeader toggle={toggleModal}>
                        {
                            editController ? 'Edit transport unit' : 'Add new transport unit'
                        }
                </ModalHeader>
                    <form onSubmit={editController ? handleFormSubmitEdit : handleFormSubmitCreate}>
                        <ModalBody>
                            <Row>
                                <Col xs={6} className='my-3'>
                                    <Label>Name</Label>
                                    <input className='form-control' type='text' placeholder='Transport unit name' name='name'
                                        onChange={handleInputChange} value={formData.name} required />
                                </Col>
                                <Col xs={6} className='my-3'>
                                    <Label>Passengers</Label>
                                    <input className='form-control' type='number' placeholder='Capacity' name='capacity'
                                        onChange={handleInputChange} value={formData.capacity} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} className='my-3'>
                                    <Label>Amenities</Label>
                                    <textarea className='form-control' type='text' placeholder='Amenities description...' rows={3}
                                        onChange={handleInputChange} value={formData.amenities} name='amenities' />
                                </Col>
                                <Col xs={6} className='my-3'>
                                    <Label>Details</Label>
                                    <textarea className='form-control' type='text' placeholder='Details description...' rows={3}
                                        onChange={handleInputChange} value={formData.details} name='details' />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className='my-3'>
                                    <Label>Transport unit image <small>(Must be at least 360px by 206px)</small></Label>
                                    <input className='form-control' type='file' onChange={onFileChange}/>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" type='button' onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" type='submit'>{editController ? 'Edit' : 'Add'}</Button>
                        </ModalFooter>
                    </form>
                </LoadingOverlay>
            </Modal>
        </Fragment>
    )
}
export default Transports
