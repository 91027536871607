import React from 'react'
// import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({
    component: Component,
    ...rest
}) => {

    return (
        <Route
            component={(props)=> (<Component {...props}/>)}
        />
    )
}
