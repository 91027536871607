import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { createNewZone, deleteZone, getAllZones, updateZone } from '../../controllers/zones_controller';
import ZonesTable from './ZonesTable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Zones = () => {
    const [zonesData, setZonesData] = useState([]);
    const [refreshController, setRefreshController] = useState(false);
    const [loaderController, setLoaderController] = useState(false);
    const [modalController, setModalController] = useState(false);
    const [editController, setEditController] = useState(false);
    const [formData, setFormData] = useState({
        name: '', label: '', zone_number: '', id_zone: ''
    });

    useEffect(() => {
        getAllZones().then((response) => {
            let responseDataFormated = [];
            response.data.forEach((item) => {
                item.id = item.id_zone;
                item.zone_number = '' + item.zone_number;
                responseDataFormated.push(item);
            });
            setZonesData(responseDataFormated);
        }).catch((e) => {
            console.log(e);
        });
        setRefreshController(false);
    }, [refreshController]);

    const openModal = () => {
        setEditController(false);
        setModalController(true);
    }

    const toggleModal = () => {
        setModalController(!modalController);
        setFormData({ name: '', label: '', zone_number: '', id_zone: '' });
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleFormSubmitCreate = (e) => {
        e.preventDefault();
        setLoaderController(true);
        let dataToSend = {
            name: formData.name,
            label: `Zone ${formData.zone_number} - ${formData.name}`,
            zone_number: formData.zone_number,
            id_zone: formData.id_zone
        }
        createNewZone(dataToSend).then((res) => {
            setFormData({ name: '', label: '', zone_number: '', id_zone: '' });
            setLoaderController(false)
            Swal.fire('Success!', 'The transport zone has been created successfully.', 'success').then(() => {
                toggleModal();
                setRefreshController(true);
            });
        }).catch((e) => {
            console.log(e);
            Swal.fire('Oops!', 'Something went wrong when creating new zone', 'error');
            setLoaderController(false);
        });
    }

    const handleFormSubmitEdit = (e) => {
        e.preventDefault();
        setLoaderController(true);
        let dataToSend = {
            name: formData.name,
            label: `Zone ${formData.zone_number} - ${formData.name}`,
            zone_number: formData.zone_number,
            id_zone: formData.id_zone
        }
        updateZone(formData.id_zone, dataToSend).then((res) => {
            setFormData({ name: '', label: '', zone_number: '', id_zone: '' });
            setLoaderController(false)
            Swal.fire('Success!', 'The transport zone has been updated successfully.', 'success').then(() => {
                toggleModal();
                setRefreshController(true);
            });
        }).catch((e) => {
            console.log(e);
            Swal.fire('Oops!', 'Something went wrong when updating zone', 'error');
            setLoaderController(false);
        });
    }

    const handleEditZone = (data) => {
        setEditController(true);
        setFormData({
            name: data.name,
            label: `Zone ${data.zone_number} - ${data.name}`,
            zone_number: data.zone_number,
            id_zone: data.id_zone
        })
        setModalController(true);
    }

    const handleDeleteZone = (zoneData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "The transport zone information will be deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteZone(zoneData.id_zone).then(() => {
                    Swal.fire('Success!', 'The transport zone has been deleted successfully.', 'success').then(() => {
                        setRefreshController(true);
                    });
                }).catch((e) => {
                    Swal.fire('Oops!', 'Something went wrong when deleting the selected zone', 'error');
                });
            }
        })
    }

    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                        <h1>TRANSPORTATION ZONES</h1>
                        <Button color="primary" onClick={openModal}>
                            <FontAwesomeIcon className="mr-2" icon="plus-circle" /> Add zone
                        </Button>
                    </Col>
                    <Col xs={12} className="my-4">
                        <ZonesTable records={zonesData} deleteHandler={handleDeleteZone} editHandler={handleEditZone} />
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modalController}  size='xl' scrollable={true} >
                <LoadingOverlay active={loaderController} spinner text='Loading'>
                    <ModalHeader toggle={toggleModal}>
                        {
                            editController ? 'Edit transport zone' : 'Add new transport zone'
                        }
                    </ModalHeader>
                    <form onSubmit={editController ? handleFormSubmitEdit : handleFormSubmitCreate}>
                        <ModalBody>
                            <Row>
                                <Col xs={6} className='my-3'>
                                    <Label>Name</Label>
                                    <input className='form-control' type='text' placeholder='Transport zone name' name='name'
                                        onChange={handleInputChange} value={formData.name} required />
                                </Col>
                                <Col xs={6} className='my-3'>
                                    <Label>Zone number</Label>
                                    <input className='form-control' type='number' placeholder='Transport zone number' name='zone_number'
                                        onChange={handleInputChange} value={formData.zone_number} required />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" type='button' onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" type='submit'>{editController ? 'Edit' : 'Add'}</Button>
                        </ModalFooter>
                    </form>
                </LoadingOverlay>
            </Modal>
        </Fragment>
    )
}

export default Zones
