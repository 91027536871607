import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
  } from 'react-router-dom';

import Reservations from '../components/reservations/Reservations';
import { AuthRouter } from './AuthRouter';
import { DashboardRoutes } from './DashboardRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <PublicRoute
                    path="/auth"
                    component={AuthRouter}
                />
                <PrivateRoute                    
                    path='/'
                    component={DashboardRoutes}
                />
                <Redirect to='/auth/login' />
            </Switch>
        </Router>
    )
}
