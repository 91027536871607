import React from 'react';
// import PropTypes from 'prop-types';
import { Router, Switch, Route } from 'react-router-dom';


export const PublicRoute = ({
    component: Component,
    ...rest
}) => {
    return (
        <Router>
            <Switch>
                <Route {...rest}
                    component={(props)=>(<Component {...props}/>)}
                />
            </Switch>
        </Router>
    )
}
